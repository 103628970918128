// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
export const locales = [
  {
    "id": "en-US",
    "name": "English",
    "base": "/"
  },
  {
    "id": "zh-CN",
    "name": "中文",
    "base": "/zh-CN"
  }
];
export const messages = {
  "en-US": {
    "header.search.placeholder": "Search",
    "header.color.mode.light": "Light Mode",
    "header.color.mode.dark": "Dark Mode",
    "header.color.mode.auto": "Follow System",
    "header.social.github": "GitHub",
    "header.social.weibo": "Weibo",
    "header.social.twitter": "Twitter",
    "header.social.gitlab": "GitLab",
    "header.social.facebook": "Facebook",
    "header.social.zhihu": "Zhihu",
    "header.social.yuque": "Yuque",
    "header.social.linkedin": "Linkedin",
    "previewer.actions.code.expand": "Show Code",
    "previewer.actions.code.shrink": "Hide Code",
    "previewer.actions.sketch": "Copy to Sketch app",
    "previewer.actions.sketch.group": "Copy as Sketch Group",
    "previewer.actions.sketch.symbol": "Copy as Sketch Symbol",
    "previewer.actions.sketch.divider": "------------------------",
    "previewer.actions.sketch.guide": "How to paste to Sketch?",
    "previewer.actions.codesandbox": "Open in CodeSandbox",
    "previewer.actions.codepen": "Open in CodePen (Not implemented)",
    "previewer.actions.stackblitz": "Open in StackBlitz",
    "previewer.actions.separate": "Open in separate page",
    "404.title": "PAGE NOT FOUND",
    "404.back": "Back to homepage",
    "api.component.name": "Name",
    "api.component.description": "Description",
    "api.component.type": "Type",
    "api.component.default": "Default",
    "api.component.required": "(required)",
    "api.component.unavailable": "Properties definition is resolving, wait a moment...",
    "api.component.loading": "Properties definition is resolving, wait a moment...",
    "api.component.not.found": "Properties definition not found for {id} component",
    "content.tabs.default": "Doc",
    "search.not.found": "No content was found",
    "layout.sidebar.btn": "Sidebar",
    "header.search.loading": "Searching...",
    "algolia.searchBox.resetButtonTitle": "Clear the query",
    "algolia.searchBox.resetButtonAriaLabel": "Clear the query",
    "algolia.searchBox.cancelButtonText": "Cancel",
    "algolia.searchBox.cancelButtonAriaLabel": "Cancel",
    "algolia.startScreen.recentSearchesTitle": "Recent",
    "algolia.startScreen.noRecentSearchesText": "No recent searches",
    "algolia.startScreen.saveRecentSearchButtonTitle": "Save this search",
    "algolia.startScreen.removeRecentSearchButtonTitle": "Remove this search from history",
    "algolia.startScreen.favoriteSearchesTitle": "Favorite",
    "algolia.startScreen.removeFavoriteSearchButtonTitle": "Remove this search from favorites",
    "algolia.errorScreen.titleText": "Unable to fetch results",
    "algolia.errorScreen.helpText": "You might want to check your network connection.",
    "algolia.footer.selectText": "to select",
    "algolia.footer.selectKeyAriaLabel": "Enter key",
    "algolia.footer.navigateText": "to navigate",
    "algolia.footer.navigateUpKeyAriaLabel": "Arrow up",
    "algolia.footer.navigateDownKeyAriaLabel": "Arrow down",
    "algolia.footer.closeText": "to close",
    "algolia.footer.closeKeyAriaLabel": "Escape key",
    "algolia.footer.searchByText": "Search by",
    "algolia.noResultsScreen.noResultsText": "No results for",
    "algolia.noResultsScreen.suggestedQueryText": "Try searching for",
    "algolia.noResultsScreen.reportMissingResultsText": "Believe this query should return results?",
    "algolia.noResultsScreen.reportMissingResultsLinkText": "Let us know.",
    "theme.header": "Theme Config",
    "theme.cancel": "Cancel",
    "theme.confirm": "Save Theme",
    "theme.choose": "Choose",
    "previewer.actions.html2canvas": "Copy to Sketch app"
  },
  "zh-CN": {
    "header.search.placeholder": "搜索",
    "header.color.mode.light": "亮色模式",
    "header.color.mode.dark": "暗色模式",
    "header.color.mode.auto": "跟随系统",
    "header.social.github": "GitHub",
    "header.social.weibo": "微博",
    "header.social.twitter": "Twitter",
    "header.social.gitlab": "GitLab",
    "header.social.facebook": "Facebook",
    "header.social.zhihu": "知乎",
    "header.social.yuque": "语雀",
    "header.social.linkedin": "Linkedin",
    "previewer.actions.code.expand": "展开代码",
    "previewer.actions.code.shrink": "收起代码",
    "previewer.actions.codesandbox": "在 CodeSandbox 中打开",
    "previewer.actions.sketch": "拷贝到 Sketch",
    "previewer.actions.sketch.group": "拷贝为 Sketch Group",
    "previewer.actions.sketch.symbol": "拷贝为 Sketch Symbol",
    "previewer.actions.sketch.divider": "----------------------",
    "previewer.actions.sketch.guide": "如何粘贴到 SKetch？",
    "previewer.actions.stackblitz": "在 StackBlitz 中打开",
    "previewer.actions.separate": "在独立页面中打开",
    "404.title": "页面未找到",
    "404.back": "返回首页",
    "api.component.name": "属性名",
    "api.component.description": "描述",
    "api.component.type": "类型",
    "api.component.default": "默认值",
    "api.component.required": "(必选)",
    "api.component.unavailable": "属性定义正在解析中，稍等片刻...",
    "api.component.loading": "属性定义正在解析中，稍等片刻...",
    "api.component.not.found": "未找到 {id} 组件的属性定义",
    "content.tabs.default": "文档",
    "search.not.found": "未找到相关内容",
    "layout.sidebar.btn": "侧边菜单",
    "header.search.loading": "正在搜索...",
    "algolia.searchBox.resetButtonTitle": "清除查询",
    "algolia.searchBox.resetButtonAriaLabel": "清除查询",
    "algolia.searchBox.cancelButtonText": "取消",
    "algolia.searchBox.cancelButtonAriaLabel": "取消",
    "algolia.startScreen.recentSearchesTitle": "最近",
    "algolia.startScreen.noRecentSearchesText": "无最近搜索",
    "algolia.startScreen.saveRecentSearchButtonTitle": "保存该搜索",
    "algolia.startScreen.removeRecentSearchButtonTitle": "从历史记录中删除该搜索",
    "algolia.startScreen.favoriteSearchesTitle": "收藏",
    "algolia.startScreen.removeFavoriteSearchButtonTitle": "从收藏中删除该搜索",
    "algolia.errorScreen.titleText": "无法获取结果",
    "algolia.errorScreen.helpText": "你可能要检查一下你的网络连接.",
    "algolia.footer.selectText": "选择",
    "algolia.footer.selectKeyAriaLabel": "选择键",
    "algolia.footer.navigateText": "浏览",
    "algolia.footer.navigateUpKeyAriaLabel": "向上",
    "algolia.footer.navigateDownKeyAriaLabel": "向下",
    "algolia.footer.closeText": "关闭",
    "algolia.footer.closeKeyAriaLabel": "退出键",
    "algolia.footer.searchByText": "搜索方式",
    "algolia.noResultsScreen.noResultsText": "无结果",
    "algolia.noResultsScreen.suggestedQueryText": "尝试搜索",
    "algolia.noResultsScreen.reportMissingResultsText": "确认这个查询应该返回结果?",
    "algolia.noResultsScreen.reportMissingResultsLinkText": "请告诉我们.",
    "theme.header": "主题设置",
    "theme.cancel": "取消",
    "theme.confirm": "保存主题",
    "theme.choose": "选择",
    "previewer.actions.html2canvas": "拷贝到 Sketch"
  }
};
