// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import { getSketchJSON } from '.';

window.addEventListener('message', (ev) => {
  if (ev.data.type === 'dumi.html2sketch.exec') {
    const { value: opts, token } = ev.data;

    getSketchJSON(document, opts).then((value) => {
      window.postMessage({ type: 'dumi.html2sketch.done', value, token }, '*');
    });
  }
});
