// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
export const components = {
  "Button": {
    "type": "COMPONENT",
    "id": "Button",
    "title": "Button",
    "propsConfig": {
      "className": "PropsWithChildren<ButtonProps>",
      "type": "object",
      "required": [
        "variant"
      ],
      "properties": {
        "variant": {
          "type": "string",
          "enum": [
            "ghost",
            "solid"
          ],
          "description": "button variant type",
          "tags": {
            "description": "button variant type",
            "description.zh-CN": "按钮展示类型"
          }
        },
        "style": {
          "type": "object",
          "className": "CSSProperties",
          "description": "component style",
          "tags": {
            "description": "component style",
            "description.zh-CN": "组件样式"
          }
        },
        "onClick": {
          "type": "function",
          "signature": {
            "isAsync": false,
            "returnType": {
              "type": "void"
            },
            "arguments": [
              {
                "key": "event",
                "type": "Event",
                "isRequired": true
              }
            ]
          },
          "className": "__type",
          "description": "button click event",
          "tags": {
            "description": "button click event",
            "description.zh-CN": "点击事件"
          }
        },
        "children": {
          "type": "reactNode",
          "className": "ReactNode"
        }
      }
    }
  }
};
