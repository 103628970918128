import React from 'react';

export const demos = {
  'button-demo-ghost': {
    component: React.memo(React.lazy(() => import( /* webpackChunkName: "Button__demos" */ '/opt/buildhome/repo/example/src/Button/demos/ghost.tsx?techStack=react'))),
    asset: {
  "type": "BLOCK",
  "id": "button-demo-ghost",
  "refAtomIds": [
    "Button"
  ],
  "dependencies": {
    "index.tsx": {
      "type": "FILE",
      "value": require('-!/opt/buildhome/repo/example/src/Button/demos/ghost.tsx?dumi-raw').default
    },
    "react": {
      "type": "NPM",
      "value": "18.1.0"
    },
    "@example/pkg": {
      "type": "NPM",
      "value": "0.0.1"
    }
  },
  "description": "ghost variant type button",
  "title": "ghost"
}
  },
  'button-demo-primary': {
    component: React.memo(React.lazy(() => import( /* webpackChunkName: "Button__demos" */ '/opt/buildhome/repo/example/src/Button/demos/primary.tsx?techStack=react'))),
    asset: {
  "type": "BLOCK",
  "id": "button-demo-primary",
  "refAtomIds": [
    "Button"
  ],
  "dependencies": {
    "index.tsx": {
      "type": "FILE",
      "value": require('-!/opt/buildhome/repo/example/src/Button/demos/primary.tsx?dumi-raw').default
    },
    "react": {
      "type": "NPM",
      "value": "18.1.0"
    },
    "@example/pkg": {
      "type": "NPM",
      "value": "0.0.1"
    }
  },
  "description": "primary variant type button",
  "title": "primary"
}
  },
};
export const frontmatter = {"title":"Button","toc":"content","filename":"src/Button/index.md","atomId":"Button","nav":{"title":"Component","order":2},"group":"Forms","description":"Button component is used to trigger an action or event, such as submitting a form, opening a Dialog, canceling an action, or performing a delete operation."};
export const toc = [{"id":"button","depth":1,"title":"Button"},{"id":"import","depth":2,"title":"Import"},{"id":"usage","depth":2,"title":"Usage"},{"id":"button-demo-ghost","depth":3,"title":"ghost"},{"id":"button-demo-primary","depth":3,"title":"primary"},{"id":"props","depth":2,"title":"Props"}];
export const texts = [{"value":"Button component is used to trigger an action or event, such as submitting a form, opening a Dialog, canceling an action, or performing a delete operation.","paraId":0,"tocIndex":0},{"value":"import { Button } from 'example';\n","paraId":1,"tocIndex":1}];
