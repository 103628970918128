// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"404":{"id":"404","path":"*","parentId":"DocLayout"},"GlobalLayout":{"id":"GlobalLayout","path":"/","parentId":"dumi-context-layout","isLayout":true},"dumi-context-layout":{"id":"dumi-context-layout","path":"/","isLayout":true},"DocLayout":{"id":"DocLayout","path":"/","parentId":"GlobalLayout","isLayout":true},"DemoLayout":{"id":"DemoLayout","path":"/","parentId":"GlobalLayout","isLayout":true},"docs/guide/markdown.zh-CN":{"path":"zh-CN/guide/markdown","id":"docs/guide/markdown.zh-CN","parentId":"DocLayout"},"docs/guide/advance.zh-CN":{"path":"zh-CN/guide/advance","id":"docs/guide/advance.zh-CN","parentId":"DocLayout"},"docs/guide/index.zh-CN":{"path":"zh-CN/guide","id":"docs/guide/index.zh-CN","parentId":"DocLayout"},"docs/guide/markdown":{"path":"guide/markdown","id":"docs/guide/markdown","parentId":"DocLayout"},"docs/guide/advance":{"path":"guide/advance","id":"docs/guide/advance","parentId":"DocLayout"},"docs/guide/index":{"path":"guide","id":"docs/guide/index","parentId":"DocLayout"},"docs/index.zh-CN":{"path":"zh-CN/","id":"docs/index.zh-CN","parentId":"DocLayout"},"docs/index":{"path":"","id":"docs/index","parentId":"DocLayout"},"components/Button/index":{"id":"components/Button/index","path":"components/button","parentId":"DocLayout","meta":{"_atom_route":true}},"components/Button/index.zh-CN":{"id":"components/Button/index.zh-CN","path":"zh-CN/components/button","parentId":"DocLayout","meta":{"_atom_route":true}},"demo-render":{"id":"demo-render","path":"~demos/:id","parentId":"DemoLayout","prerender":false}} as const;
  return {
    routes,
    routeComponents: {
'404': React.lazy(() => import(/* webpackChunkName: "dumi__pages__404" */'@/dumi__pages/404')),
'GlobalLayout': React.lazy(() => import(/* webpackChunkName: "dumi__theme__layouts__GlobalLayout" */'@/dumi__theme__layouts/GlobalLayout')),
'dumi-context-layout': React.lazy(() => import(/* webpackChunkName: "dumi__theme__ContextWrapper" */'@/dumi__theme/ContextWrapper')),
'DocLayout': React.lazy(() => import(/* webpackChunkName: "dumi__theme__layouts__DocLayout" */'@/dumi__theme__layouts/DocLayout')),
'DemoLayout': React.lazy(() => import(/* webpackChunkName: "dumi__theme__layouts__DemoLayout" */'@/dumi__theme__layouts/DemoLayout')),
'docs/guide/markdown.zh-CN': React.lazy(() => import(/* webpackChunkName: "docs__guide__markdown.zh-CN.md" */'/opt/buildhome/repo/example/docs/guide/markdown.zh-CN.md')),
'docs/guide/advance.zh-CN': React.lazy(() => import(/* webpackChunkName: "docs__guide__advance.zh-CN.md" */'/opt/buildhome/repo/example/docs/guide/advance.zh-CN.md')),
'docs/guide/index.zh-CN': React.lazy(() => import(/* webpackChunkName: "docs__guide__index.zh-CN.md" */'/opt/buildhome/repo/example/docs/guide/index.zh-CN.md')),
'docs/guide/markdown': React.lazy(() => import(/* webpackChunkName: "docs__guide__markdown.md" */'/opt/buildhome/repo/example/docs/guide/markdown.md')),
'docs/guide/advance': React.lazy(() => import(/* webpackChunkName: "docs__guide__advance.md" */'/opt/buildhome/repo/example/docs/guide/advance.md')),
'docs/guide/index': React.lazy(() => import(/* webpackChunkName: "docs__guide__index.md" */'/opt/buildhome/repo/example/docs/guide/index.md')),
'docs/index.zh-CN': React.lazy(() => import(/* webpackChunkName: "docs__index.zh-CN.md" */'/opt/buildhome/repo/example/docs/index.zh-CN.md')),
'docs/index': React.lazy(() => import(/* webpackChunkName: "docs__index.md" */'/opt/buildhome/repo/example/docs/index.md')),
'components/Button/index': React.lazy(() => import(/* webpackChunkName: "Button__index.md" */'/opt/buildhome/repo/example/src/Button/index.md')),
'components/Button/index.zh-CN': React.lazy(() => import(/* webpackChunkName: "Button__index.zh-CN.md" */'/opt/buildhome/repo/example/src/Button/index.zh-CN.md')),
'demo-render': React.lazy(() => import(/* webpackChunkName: "dumi__pages__Demo" */'@/dumi__pages/Demo')),
},
  };
}
